import { Controller } from "stimulus"
import Choices from "choices.js"


export default class extends Controller {
  static targets = ["showhide", "select"]

  connect() {
    this.elementChoices = new Choices(this.selectTarget, {removeItemButton: true})

    //Ancho mínimo dinámico del input del componente Choice
    //this.elementChoices.containerOuter.element.style.width = '300px';//Select
    const inputElement = this.elementChoices.input.element;
    inputElement.addEventListener('input', this.handleInputChange.bind(this));
  }
  
  handleInputChange(event) {
    const inputElement = this.elementChoices.input.element;
    if (event.target.value.trim() !== '') {
      if(parseInt(inputElement.style.width,10)<15)
        inputElement.style.width = '15ch';
    } 
  }

  change(event) {
    
    this.showhideTarget.classList.add("hidden")

    let key = event.target.value
    let options = this.options[key]

    if (options && options.length > 0) {
      this.setOptions(options)
      this.showhideTarget.classList.remove("hidden")
    }
    
  }
  get options() {
    return JSON.parse(this.element.getAttribute('data-options'))
  }
  
  setOptions(data) {
    this.elementChoices.clearStore()
    this.elementChoices.setChoices(data, 'id', 'name', true)
  }
}